const colors = {
    red: '#821E1E',
    alt: 'rgb(230 70 1)',
    timberwolf: '#D1CFC7',
    dark: '#343434',
    lavendarBlush: '#FFEBF0',
    main: 'rgb(180 15 15)', //kw red
    bg: '#eeeeee'
}

export {
    colors
}